<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="3">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-align-justify"></i><strong> Şablonlar</strong>
            </div>
            <div slot="footer">
              <b-button @click="save_permission_scheme()" variant="success"> <i class="fa fa-save"></i> Kaydet</b-button>
            </div>
            <b-nav vertical>
              <b-nav-item v-for="(scheme, scheme_ind) in permission_scheme.scheme" :key="'permission_scheme.scheme_' + scheme_ind">
                <span @click="show_scheme_value = scheme.value" :style="show_scheme_value === scheme.value ? 'color: #4dbd74; font-weight: bold' : 'color: #8f9ba6; font-weight: bold;'">--> {{ scheme.label }} 
                    <b-badge v-if="permission_scheme.default && permission_scheme.default === scheme.value" variant="success">Default</b-badge>
                  </span>
              </b-nav-item>
              <b-nav-item>
                <span @click="f_showNewScheme()"><i>+ Yeni Şablon Ekle</i></span>
              </b-nav-item>
            </b-nav>
          </b-card>
        </b-col>
        <b-col cols="9">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-align-justify"></i><strong> Seçili Şablon Yetkileri</strong>
              <template v-if="show_scheme_value !== ''">
                <b-button variant="primary" @click="f_showSchemeDetail()" class="pull-right">{{ '"' + permission_scheme.scheme[show_scheme_value].label + '" şemasını düzenle' }}</b-button>
                <b-button v-if="!permission_scheme.default || (permission_scheme.default && permission_scheme.default !== show_scheme_value)" variant="warning" @click="f_makeDefaultScheme()" class="pull-right">{{ '"' + permission_scheme.scheme[show_scheme_value].label + '" şemasını default seç' }}</b-button>
              </template>
            </div>
            <template v-if="show_scheme_value !== ''">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox-group v-model="permission_scheme.scheme[show_scheme_value].permission" stacked switches style="border-radius: 5px; border: solid 1px #a0d0bc; padding: 5px;">
                    <template v-for="(list_1, list_ind_1) in all_permissions_data.list">
                      <ul>
                        <li><b>{{ list_1.label }}</b></li>
                        <ul>
                          <template v-for="(perm_1, perm_ind_1) in list_1.permission">
                            <li>
                              <b-form-checkbox :id="perm_1.value" :value="perm_1.value">{{ perm_1.label }}</b-form-checkbox>
                            </li>
                          </template>
                          <template v-for="(list_2, list_ind_2) in list_1.list">
                            <li><b>{{ list_2.label }}</b></li>
                            <ul>
                              <template v-for="(perm_2, perm_ind_2) in list_2.permission">
                                <li>
                                  <b-form-checkbox :id="perm_2.value" :value="perm_2.value">{{ perm_2.label }}</b-form-checkbox>
                                </li>
                              </template>
                              <template v-for="(list_3, list_ind_3) in list_2.list">
                                <li><b>{{ list_3.label }}</b></li>
                                <ul>
                                  <template v-for="(perm_3, perm_ind_3) in list_3.permission">
                                    <li>
                                      <b-form-checkbox :id="perm_3.value" :value="perm_3.value">{{ perm_3.label }}</b-form-checkbox>
                                    </li>
                                  </template>
                                </ul>
                              </template>
                            </ul>
                          </template>
                        </ul>
                      </ul>
                    </template>
                  </b-form-checkbox-group>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="d_showNewAgreement" v-model="d_showNewScheme" title="Yeni Şema Ekle" centered header-bg-variant="info" header-text-variant="dark">
      <b-row>
        <b-col cols="12">
          <b-input-group prepend="Scheme Label" label-for="d_newSchemeData.label" label-cols="6">
            <b-form-input type="text" class="form-control-warning" id="d_newSchemeData.label" v-model="d_newSchemeData.label"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-input-group prepend="Scheme Value" label-for="d_newSchemeData.value" label-cols="6">
            <b-form-input type="text" class="form-control-warning" id="d_newSchemeData.value" v-model="d_newSchemeData.value"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button variant="success" :disabled="!d_newSchemeData.value || !d_newSchemeData.label || permission_scheme.scheme[d_newSchemeData.value]" @click="f_addNewScheme()">{{ $t('wdm16.4061') }}</b-button>
        <b-button variant="danger" @click="d_showNewScheme = false">{{ $t('wdm16.4053') }}</b-button>
      </template>
    </b-modal>
    <b-modal id="d_showSchemeDetail" v-model="d_showSchemeDetail" title="Şema Detayları" centered no-close-on-esc centered no-stacking no-close-on-backdrop hide-header-close header-bg-variant="info" header-text-variant="dark">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Şablon Başlığı" label-for="label" :label-cols="4">
            <b-form-input type="text" id="label" v-model="d_schemeDetailData.label" required></b-form-input>
          </b-form-group>
          <b-form-group label="Şablon Değeri" label-for="value" :label-cols="4">
            {{ d_schemeDetailData.value }}
          </b-form-group>
          <b-form-group label="Promocode Olarak Kullanılabilir mi?" label-for="is_promo" :label-cols="4">
            <b-form-checkbox v-model="d_schemeDetailData.is_promo" switch :value="1" :unchecked-value="0">
            </b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="d_schemeDetailData.is_promo" label="Promocode Değeri" label-for="promocode" :label-cols="4">
            <b-form-input type="text" id="label" v-model="d_schemeDetailData.promocode" :formatter="f_formatPromocodeRegex" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button variant="success" :disabled="!d_schemeDetailData.label || (d_schemeDetailData.is_promo && d_schemeDetailData.promocode === '') || (d_schemeDetailData.promocode !== '' && d_promocodesWithSchemeValue[d_schemeDetailData.promocode] && d_promocodesWithSchemeValue[d_schemeDetailData.promocode] !== show_scheme_value)" @click="f_saveSchemeDetail()">{{ $t('wdm16.4061') }}</b-button>
        <b-button variant="danger" @click="d_showSchemeDetail = false">{{ $t('wdm16.4053') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionService from '@/services/permission';
import { WisdomRegex } from '@/services/public/functions';
import PermissionSchemeService from '@/services/permission_scheme';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'PermissionScheme',
  components: {
    Modal
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      new_empty_permission: { 'value': '', 'label': '' },
      show_scheme_value: '',
      all_permissions_data: [],
      permission_scheme: {},
      user: {},
      d_newSchemeData: { 'value': '', 'label': '' },
      d_showNewScheme: false,
      d_showSchemeDetail: false,
      d_promocodesWithSchemeValue: {},
      d_schemeDetailData: { 'value': '', 'label': '', 'is_promo': false, 'promocode': '' }
    }
  },
  created: function () {
    this.get_all_permissions();
    this.get_permission_scheme();
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    f_makeDefaultScheme () {
      this.permission_scheme.default = this.show_scheme_value;
      this.$forceUpdate();
    },
    f_formatPromocodeRegex (value, event) {
      return WisdomRegex.regex1(value).value;
    },
    f_saveSchemeDetail: function () {
      this.permission_scheme.scheme[this.show_scheme_value].label = this.d_schemeDetailData.label;
      this.permission_scheme.scheme[this.show_scheme_value].is_promo = this.d_schemeDetailData.is_promo;
      this.permission_scheme.scheme[this.show_scheme_value].promocode = this.d_schemeDetailData.promocode;
      this.f_prepareAllPromocodes();
      this.d_showSchemeDetail = false;
    },
    f_showSchemeDetail: function () {
      this.d_schemeDetailData.value = this.permission_scheme.scheme[this.show_scheme_value].value;
      this.d_schemeDetailData.label = this.permission_scheme.scheme[this.show_scheme_value].label;
      this.d_schemeDetailData.is_promo = this.permission_scheme.scheme[this.show_scheme_value].is_promo;
      this.d_schemeDetailData.promocode = this.permission_scheme.scheme[this.show_scheme_value].promocode;
      this.d_showSchemeDetail = true;
    },
    f_prepareAllPromocodes: function () {
      this.d_promocodesWithSchemeValue = {};
      for (let scheme_value in this.permission_scheme.scheme) {
        if (this.permission_scheme.scheme[scheme_value].promocode) {
          this.d_promocodesWithSchemeValue[this.permission_scheme.scheme[scheme_value].promocode] = scheme_value;
        }
      }
    },
    f_addNewScheme: function () {
      this.permission_scheme.scheme[this.d_newSchemeData.value] = { 'label': this.d_newSchemeData.label, 'value': this.d_newSchemeData.value, 'permission': [], 'is_promo': false, 'promocode': '' };
      this.$forceUpdate();
      this.d_showNewScheme = false;
    },
    f_showNewScheme: function () {
      if (!this.permission_scheme.scheme) {
        this.permission_scheme.scheme = {}
      }
      this.d_newSchemeData = { 'value': '', 'label': '' };
      this.d_showNewScheme = true;
    },
    get_all_permissions: function () {
      PermissionService.get_permission_tree()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.all_permissions_data = resp.data.result;
          }
        });
    },
    get_permission_scheme: function () {
      PermissionSchemeService.get_permission_scheme()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.permission_scheme = resp.data.result;
            this.f_prepareAllPromocodes();
          }
        });
    },
    save_permission_scheme: function () {
      PermissionSchemeService.save_permission_scheme(this.permission_scheme)
        .then(resp => {
          this.permission_scheme = resp.data.result;
          alert('kayıt tamamlandı');
        });
    }
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  watch: {}
}

</script>

