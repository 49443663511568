import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save_permission_scheme (data) {
    return Vue.http.put(API_BASE_URL + 'save_permission_scheme/', data);
  },
  get_permission_scheme () {
    return Vue.http.get(API_BASE_URL + 'get_permission_scheme/');
  },
};
